import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';
import TagCard from '../../components/TagCard';

import SidebarWrapper from '../../components/PageLayout/Sidebar';
import PostCard from '../../components/PostCard';
import SEO from '../../components/Seo';

const Math = ({ data }) => (
  <Layout className="outerPadding">
    <Layout className="container">
      <Header />
      <SEO
        title="Teacher Trelyn's PSLE Materials"
        description="20 Years of Tutoring Experience"
        path="/"
      />
      <SidebarWrapper>

      <div className="marginTopTitle">
          <h1 className="titleSeparate">Topics</h1>

          <Row gutter={[20, 20]}>
          <ul>
            <li><a href='#sectionSet'>Whole Number: Set Theory</a></li>
            <li><a href='#sectionPatt'>Whole Number: Pattern</a></li>
            <li><a href='#sectionInt'>Whole Number: Internal Transfer</a></li>
            <li><a href='#sectionExt'>Whole Number: External Transfer</a></li>

            <li><a href='#sectionSim'>Whole Number: Simultaneous Equations</a></li>
            <li><a href='#sectionDouble'>Whole Number: Double If</a></li>
            <li><a href='#sectionGuess'>Whole Number: Guess and Check</a></li>
            <li><a href='#sectionGap'>Whole Number: Gap Interval</a></li>
            <li><a href='#sectionRep'>Whole Number: Repeated Identity</a></li>
            <li><a href='#sectionStacked'>Whole Number: Stacked Model</a></li>
            <li><a href='#sectionWMGaps'>Whole Number: Gaps and Differences</a></li>
            <li><a href='#sectionWMRe'>Whole Number: Rearrangement</a></li>

            <li><a href='#sectionDis'>Fraction: Discrepency </a></li>
            <li><a href='#sectionF1'>Fraction: Equal Fractions</a></li>
            <li><a href='#sectionF2'>Fraction: Remainder Concept</a></li>
            <li><a href='#sectionF3'>Fraction: Repeated Internal Transfer</a></li>
            <li><a href='#sectionF4'>Fraction: Total Concepts</a></li>

            <li><a href='#sectionUnits'>Fraction: Units and Parts</a></li>
            <li><a href='#sectionCut'>Fraction: Split Cut</a></li>
            <li><a href='#sectionTricky'>Fraction: Tricky Remainder</a></li>



           
            
  
          </ul>

          <ul>

           <li><a href='#sectionRatio1'>Ratio: Common Total</a></li>
            <li><a href='#sectionRatio2'>Ratio: Double If</a></li>
            <li><a href='#sectionRatio3'>Ratio: External Transfer</a></li>
            <li><a href='#sectionRatio4'>Ratio: Internal Transfer</a></li>

            <li><a href='#sectionPct1'>Percentage: GST</a></li>
            <li><a href='#sectionPct2'>Percentage: Discount</a></li>
            <li><a href='#sectionPct3'>Percentage: Discount & GST</a></li>
            <li><a href='#sectionPct4'>Percentage: Increase or Decrease</a></li>


            <li><a href='#sectionSpeed1'>Speed: Sum of Speed</a></li>
            <li><a href='#sectionSpeed2'>Speed: Difference in Speed</a></li>
            <li><a href='#sectionSpeed3'>Speed: Speed vs. Time</a></li>

            <li><a href='#sectionArea'>Circle: Area of circle</a></li>
            <li><a href='#sectionCir'>Circle: Circumference of Circle</a></li>

            <li><a href='#sectionGeo'>Geometry: Quadrilateral</a></li>
            <li><a href='#sectionGeoTri'>Geometry: Triangle</a></li>
            <li><a href='#sectionQuad'>Area and Perimeter: Quadrilateral</a></li> 


            <li><a href='#sectionTri'>Area and Perimeter: Triangle</a></li> 



            <li><a href='#sectionAlg1'>Algebra: Operations</a></li>
            <li><a href='#sectionAlg2'>Algebra: Substitution</a></li> 

            <li><a href='#sectionAve'>Average: Basic Concept</a></li>

</ul>

          <ul>



            <li><a href='#sectionAve2'>Average: Incorrect Entry</a></li>
            <li><a href='#sectionAve3'>Average: Challenging Cases</a></li> 

            <li><a href='#sectionVol'>Volume: Basic Concept</a></li>
            <li><a href='#sectionVol2'>Volume: Partial Volume</a></li>
            <li><a href='#sectionVol3'>Volume: Making Height Equal</a></li>
            <li><a href='#sectionVol4'>Volume: Taps</a></li>
            <li><a href='#sectionVol5'>Volume: Maximum Cubes in a Box</a></li>

            <li><a href='#sectionRateAreaVol'>Rate: Area and Volume</a></li>
            <li><a href='#sectionRatePerson'>Rate: Person</a></li>
            <li><a href='#sectionRateTime'>Rate: Time</a></li>
            <li><a href='#sectionRateMassLength'>Rate: Mass and Length</a></li>


            
            <li><a href='#sectionPie1'>Pie Chart: Basic Concept</a></li>


            
            <li><a href='#sectionCompass'>Compass: Basic Concept</a></li>
               

          </ul>


          </Row>
        </div>

        <br />
        <br />

        <div id='sectionSet' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Set Theory</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.set && data.set.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


        <div id='sectionPatt' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Pattern</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.pattern && data.pattern.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


        <div id='sectionInt' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Internal Transfer</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.internal && data.internal.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

<br />
 <br />


        <div id='sectionExt' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: External Transfer</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.external && data.external.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>




        <br />
 <br />


        <div id='sectionSim' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Simultaneous Equations</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.sim && data.sim.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



        <br />
 <br />


        <div id='sectionDouble' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Double If</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.double && data.double.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>




         <br />
 <br />


        <div id='sectionGuess' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Guess and Check</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.guess && data.guess.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



<br />
 <br />


        <div id='sectionGap' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Gap Interval</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.gap && data.gap.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

         <br />
 <br />


        <div id='sectionRep' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Repeated Identity</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.rep && data.rep.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>


        <br />
 <br />


        <div id='sectionStacked' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Stacked Model</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.stacked && data.stacked.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
 <br />

        <div id='sectionDis' className="marginTopTitle">
          <h1 className="titleSeparate">Fraction: Discrepancy</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.dis && data.dis.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>


  <br />
 <br />

        <div id='sectionF1' className="marginTopTitle">
          <h1 className="titleSeparate">Fraction: Equal Fractions</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.f1 && data.f1.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

 <br />
 <br />

        <div id='sectionF2' className="marginTopTitle">
          <h1 className="titleSeparate">Fraction: Remainder Concept</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.f2 && data.f2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
 <br />

        <div id='sectionF3' className="marginTopTitle">
          <h1 className="titleSeparate">Fraction: Repeated Internal Transfer </h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.f3 && data.f3.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

          <br />
 <br />

        <div id='sectionF4' className="marginTopTitle">
          <h1 className="titleSeparate">Fraction: Total Concepts</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.f4 && data.f4.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>


         <br />
 <br />

        <div id='sectionUnits' className="marginTopTitle">
          <h1 className="titleSeparate">Fraction: Units and Parts</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.units && data.units.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

<br />
 <br />

        <div id='sectionCut' className="marginTopTitle">
          <h1 className="titleSeparate">Fraction: Split Cut</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.cuts && data.cuts.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
 <br />

        <div id='sectionTricky' className="marginTopTitle">
          <h1 className="titleSeparate">Fraction: Tricky Remainder</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.tricky && data.tricky.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>




         <br />
 <br />

        <div id='sectionRatio1' className="marginTopTitle">
          <h1 className="titleSeparate">Ratio: Common Total</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.ratio1 && data.ratio1.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>




        <br />
 <br />

        <div id='sectionRatio2' className="marginTopTitle">
          <h1 className="titleSeparate">Ratio: Double If</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.ratio2 && data.ratio2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



         <br />
 <br />

        <div id='sectionRatio3' className="marginTopTitle">
          <h1 className="titleSeparate">Ratio: External Transfer</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.ratio3 && data.ratio3.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



<br />
 <br />

        <div id='sectionRatio4' className="marginTopTitle">
          <h1 className="titleSeparate">Ratio: Internal Transfer</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.ratio4 && data.ratio4.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



        <br />
 <br />

        <div id='sectionPct1' className="marginTopTitle">
          <h1 className="titleSeparate">Percentage: GST</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.pct1 && data.pct1.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>


         <br />
 <br />

        <div id='sectionPct2' className="marginTopTitle">
          <h1 className="titleSeparate">Percentage: Discount</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.pct2 && data.pct2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>




        <br />
 <br />

        <div id='sectionPct3' className="marginTopTitle">
          <h1 className="titleSeparate">Percentage: Discount & GST</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.pct3 && data.pct3.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



         <br />
 <br />

        <div id='sectionPct4' className="marginTopTitle">
          <h1 className="titleSeparate">Percentage: Increase or Decrease</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.pct4 && data.pct4.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>





         <br />
 <br />

        <div id='sectionSpeed1' className="marginTopTitle">
          <h1 className="titleSeparate">Speed: Sum of Speed</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.s1 && data.s1.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>


        <br />
 <br />

        <div id='sectionSpeed2' className="marginTopTitle">
          <h1 className="titleSeparate">Speed: Difference in Speed</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.s2 && data.s2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



         <br />
 <br />

        <div id='sectionSpeed3' className="marginTopTitle">
          <h1 className="titleSeparate">Speed: Speed vs. Time</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.s3 && data.s3.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



         <br />
 <br />

        <div id='sectionArea' className="marginTopTitle">
          <h1 className="titleSeparate">Circle: Area of Circle</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.area && data.area.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>


         <br />
 <br />

        <div id='sectionCir' className="marginTopTitle">
          <h1 className="titleSeparate">Circle: Circumference of Circle</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.cir && data.cir.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>


        <br />
 <br />

        <div id='sectionGeo' className="marginTopTitle">
          <h1 className="titleSeparate">Geometry: Quadrilateral</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.geo && data.geo.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



        <br />
 <br />

        <div id='sectionGeoTri' className="marginTopTitle">
          <h1 className="titleSeparate">Geometry: Triangle</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.geotri && data.geotri.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



        <br />
 <br />

        <div id='sectionQuad' className="marginTopTitle">
          <h1 className="titleSeparate">Area and Perimeter: Quadrilateral</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.quad && data.quad.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



         <br />
 <br />

        <div id='sectionTri' className="marginTopTitle">
          <h1 className="titleSeparate">Area and Perimeter: Triangle</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.tri && data.tri.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



<br />
 <br />

        <div id='sectionAlg1' className="marginTopTitle">
          <h1 className="titleSeparate">Algebra: Operations</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.alg && data.alg.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>




        <br />
 <br />

        <div id='sectionAlg2' className="marginTopTitle">
          <h1 className="titleSeparate">Algebra: Substitution</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.alg2 && data.alg2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>








        <br />
 <br />

       

        <div id='sectionAve' className="marginTopTitle">
          <h1 className="titleSeparate">Average: Basic Concept</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.average && data.average.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



<br />
 <br />

        <div id='sectionAve2' className="marginTopTitle">
          <h1 className="titleSeparate">Average: Incorrect Entry</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.average2 && data.average2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



        <br />
 <br />

        <div id='sectionAve3' className="marginTopTitle">
          <h1 className="titleSeparate">Average: Challenging Cases</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.average3 && data.average3.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>


        <br />
 <br />

        <div id='sectionVol' className="marginTopTitle">
          <h1 className="titleSeparate">Volume: Basic Concept</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.vol && data.vol.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

         <br />
 <br />

        <div id='sectionVol2' className="marginTopTitle">
          <h1 className="titleSeparate">Volume: Partial Volume</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.vol2 && data.vol2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

         <br />
 <br />

        <div id='sectionVol3' className="marginTopTitle">
          <h1 className="titleSeparate">Volume: Making Height Equal</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.vol3 && data.vol3.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

         <br />
 <br />

        <div id='sectionVol4' className="marginTopTitle">
          <h1 className="titleSeparate">Volume: Taps</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.vol4 && data.vol4.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

         <br />
 <br />

        <div id='sectionVol5' className="marginTopTitle">
          <h1 className="titleSeparate">Volume: Maximum Cubes in a Box</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.vol5 && data.vol5.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>







        <br />
 <br />

        <div id='sectionRateAreaVol' className="marginTopTitle">
          <h1 className="titleSeparate">Rate: Area and Volume</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.rateareavol && data.rateareavol.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

          <br />
 <br />

        <div id='sectionRatePerson' className="marginTopTitle">
          <h1 className="titleSeparate">Rate: Person</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.rateperson && data.rateperson.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



          <br />
 <br />

        <div id='sectionRateTime' className="marginTopTitle">
          <h1 className="titleSeparate">Rate: Time</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.ratetime && data.ratetime.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



          <br />
 <br />

        <div id='sectionRateMassLength' className="marginTopTitle">
          <h1 className="titleSeparate">Rate: Mass and Length</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.ratemasslength && data.ratemasslength.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>




         <br />
 <br />

        <div id='sectionWMRe' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Rearrangement</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.wm1 && data.wm1.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



        <br />
 <br />

        <div id='sectionWMGaps' className="marginTopTitle">
          <h1 className="titleSeparate">Whole Number: Gaps and Differences</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.wm2 && data.wm2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



         <br />
 <br />

        <div id='sectionPie1' className="marginTopTitle">
          <h1 className="titleSeparate">Pie Chart: Basic Concept</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.pie1 && data.pie1.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>



        <br />
 <br />

        <div id='sectionCompass' className="marginTopTitle">
          <h1 className="titleSeparate">Compass: Basic Concept</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.compass && data.compass.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>








        
      </SidebarWrapper>
    </Layout>
  </Layout>
);

Math.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
{
  set: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Set"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  pattern: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Patterns"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  internal: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Internal"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  external: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "External"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  sim: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Sim"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  double: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Double"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  guess: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Guess"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  gap: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Gap"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  stacked: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Stacked"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  rep: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Repeated"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  dis: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Dis"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  f1: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "F1"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  f2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "F2"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  f3: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "F3"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  f4: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "F4"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  units: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Units"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  cuts: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Cuts"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

    tricky: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Tricky"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }


  ratio1: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Ratio Common Total"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }


  ratio2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Ratio Double If"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }


  ratio3: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Ratio External Transfer"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }


  ratio4: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Ratio Internal Transfer"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }




  pct1: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Percentage GST"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }


  pct2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Percentage Discount"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  pct3: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Percentage Discount and GST"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }


  pct4: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Percentage Increase or Decrease"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }


   s1: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Sum of Speed"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }


  s2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Opposite"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s3: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Relationship"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }



    s4: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Meeting"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

      geo: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Geo"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  area: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Area of Circle"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  cir: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Circumference of Circle"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }


  geotri: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "GeoTriangles"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  quad: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Quad"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  tri: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Triangle"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  alg: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Algebra"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  alg2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Algebra2"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  average: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Average"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  average2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Average2"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  average3: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Average3"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  vol: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Volume"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  vol2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Volume2"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  vol3: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Volume3"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  vol4: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Volume4"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  vol5: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "Volume5"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  rateareavol: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "rate-area-vol"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  rateperson: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "rate-person"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  ratetime: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "rate-time"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  ratemasslength: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "rate-mass-length"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  wm1: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "whole-rearrangement"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  wm2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "whole-gaps"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  pie1: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "pie-chart-basic"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  folded: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "folded paper"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  compass: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Math"}, topic: {eq: "compass"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

}
`;

export default Math;
